import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'

const dummyData = [
  {
    contract: 'Vulcan Forged',
    hourly: '1,000',
    daily: '54,000',
    weekly: '10,333,000'
  },
  {
    contract: 'Vulcan Dex',
    hourly: '3,100',
    daily: '32,000',
    weekly: '198,000'
  },
  {
    contract: 'Husl',
    hourly: '600',
    daily: '11,000',
    weekly: '98,112'
  },
  {
    contract: 'Berserk',
    hourly: '122',
    daily: '34,444',
    weekly: '9,233,000'
  },
  {
    contract: 'Sineverse',
    hourly: '678',
    daily: '2,000',
    weekly: '100,120'
  },
  {
    contract: 'NYC Fashion',
    hourly: '34',
    daily: '10,243',
    weekly: '10,344'
  },
  {
    contract: 'Vulcan Assasin',
    hourly: '3450',
    daily: '22,000',
    weekly: '55,000'
  }
]

export const TableChart = () => {

  return (
    <TableContainer w={'100%'}>
      <Table variant='simple' size="sm">
        <Thead>
          <Tr>
            <Th>Contract</Th>
            <Th isNumeric>Hourly $Lava</Th>
            <Th isNumeric>Daily $Lava</Th>
            <Th isNumeric>Weekly $Lava</Th>
          </Tr>
        </Thead>
        <Tbody>
          {dummyData.map((row, index) => (
            <Tr key={index}>
              <Td>{row.contract}</Td>
              <Td isNumeric>{row.hourly}</Td>
              <Td isNumeric>{row.daily}</Td>
              <Td isNumeric>{row.weekly}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}