import React, { useState, useRef, useEffect, useCallback } from 'react';

import { Avatar, Button, ButtonGroup, IconButton, Select, Tooltip, Switch } from '@chakra-ui/react'
import { select, interpolate, easeSin, curveBumpX, link, easeQuad, easeCircleOut } from "d3";
import { LavaTransaction, randomIntFromInterval, useAudio, useEffectOnce, useWindowFocus } from './utils';
import { findIndex, isEqual, find } from 'lodash';
import { IoPlaySharp, IoPause, IoSettingsSharp, IoRefreshOutline } from 'react-icons/io5'
import axios from 'axios';

import notificationAudio from '../assets/notification.mp3';

import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Box,
} from '@chakra-ui/react'

import { useInterval } from 'usehooks-ts'
import { TransactionInfo } from '../components/transaction.info';
import { Modal } from '../components/modal';

const lavaValueDivide = 1000000000000000000

const getCircleX = (radians: number, radius: number) => Math.cos(radians) * radius;
const getCircleY = (radians: number, radius: number) => Math.sin(radians) * radius;

const degsToRads = (deg: number) => (deg * Math.PI) / 180.0;
const radsToDegs = (rad: number) => rad * 180 / Math.PI;

const calcX = (degree: number, radius: number, add: number) => getCircleX(degsToRads(degree), radius) + add
const calcY = (degree: number, radius: number, add: number) => getCircleY(degsToRads(degree), radius) + add

function pathTween(path: any): any {
  const length = path.node().getTotalLength(); // Get the length of the path
  const r = interpolate(0, length); // Set up interpolation from 0 to the path length

  const randX = randomIntFromInterval(-20, 20)
  const randY = randomIntFromInterval(-20, 20)

  return function (t: any): any {
    const point = path.node().getPointAtLength(r(t)); // Get the next point along the path
    // @ts-ignore
    select(this) // Select the circle
      .attr("cx", point.x + randX) // Set the cx
      .attr("cy", point.y + randY) // Set the cy
  }
}

const generateRandomDataOld = ({ dataPointsHolder, paths, version1, reversedPaths, pulseWallets, dataPointMinSize, dataPointDelay, points, startX, startY, isReverse, dataPointClickCallback, visibleLabels, setPlayAudio, legends, wallets }: {
  dataPointsHolder: any,
  paths: any,
  reversedPaths: any,
  points: Array<any>,
  startX: number,
  startY: number,
  isReverse: boolean,
  dataPointDelay: number,
  dataPointMinSize: number,
  visibleLabels: number,
  version1: boolean,
  dataPointClickCallback: (node: any) => void,
  setPlayAudio: any,
  pulseWallets: boolean,
  legends: any,
  wallets: Array<{ degree: number, text: string }>
}) => {
  const pointData = []
  let rangeMin = 0
  let rangeMax = 0

  for (let i = 0; i < 30; i++) {
    // random sizes
    const randIndex = randomIntFromInterval(0, visibleLabels - 1)

    // this is the root point ($LAVA)
    if (wallets[randIndex].degree === 90) {
      continue
    }

    // this will be the transaction size
    const radiusSize = randomIntFromInterval(dataPointMinSize, dataPointMinSize + 20)
    // animation start delay
    const delay = randomIntFromInterval(0, 50)
    // animation duration
    const duration = randomIntFromInterval(dataPointDelay, dataPointDelay + 500)
    // transaction endpoint
    const randomEndpoint = !isReverse ? paths[points[randIndex]] : reversedPaths[points[randIndex]]
    // get the wallet data
    const wallet = wallets[randIndex]

    rangeMin = Math.min(rangeMin, radiusSize)
    rangeMax = Math.max(rangeMax, radiusSize)

    pointData.push({
      randIndex,
      radiusSize,
      delay,
      duration,
      randomEndpoint,
      startX,
      startY,
      wallet,
    })
  }

  const newGroup = dataPointsHolder.append('g')

  const circles = newGroup
    .selectAll('circle')
    .data(pointData)
    .enter()
    .append('circle')
    .attr("cx", (d: any) => d.startX) //Starting x
    .attr("cy", (d: any) => d.startY) //Starting y
    .attr("r", (d: any) => d.radiusSize)
    .attr('fill', '#FFD439')
    .style('cursor', 'pointer')
    .on("mouseover",
      function () {
        // @ts-ignore
        select(this).attr('stroke', 'rgba(255,255,255,.5)').attr('stroke-width', '6px')
      })
    .on("mouseout",
      function () {
        // @ts-ignore
        select(this).attr('stroke-width', '0px')
      })
    .on("click",
      // @ts-ignore
      function (d) {
        dataPointClickCallback(d)
      })

  function pulseLegend(degree: string, color: string = '#FFD439', endColor: string = '#69a3b2', highlight: boolean = false) {
    legends
      .select(`#legend__${degree}`)
      .transition()
      .duration(200)
      .attr('fill', color)
      .on('start', function (d: any) {
        // @ts-ignore
        select(this)
          .style('filter', 'drop-shadow(0px 0px 6px rgba(255, 255, 255, 0.5))')
      })
      .on('end', function (d: any) {
        // @ts-ignore
        select(this)
          .transition()
          .duration(200)
          .attr('fill', endColor)
        // @ts-ignore  
        select(this)
          .style('filter', 'drop-shadow(0px 0px 0px rgba(255, 255, 255, 0.5))')
      })
  }

  circles.transition()
    .ease(easeCircleOut) //easeSin
    .delay((d: any) => d.delay)
    .duration((d: any) => d.duration)
    .tween("pathTween", function (d: any) {
      return pathTween(d.randomEndpoint)
    })
    .attr('r', (d: any) => version1 ? d.radiusSize : 1)
    .style("fill", "rgba(255, 0, 0,0.55)")
    .on('end', function (d: any) {
      if (d.wallet.degree !== 90 && d.wallet.degree !== '90' && pulseWallets) {
        pulseLegend(d.wallet.degree.toString(), "#94A7B8", '#69a3b2', false)
      }
      // @ts-ignore
      select(this)
        .transition()
        .duration(100)
        .attr('r', 0)
        .attr('fill', '#252d40')
        .on('end', function () {
          select(this).remove()
        })
    })

  pulseLegend('90')
  setPlayAudio()
}


const generateRandomData = ({ dataPointsHolder, paths, version1, reversedPaths, pulseWallets, dataPointMinSize, tooltipHolder, dataPointDelay, points, startX, startY, isReverse, dataPointClickCallback, visibleLabels, setPlayAudio, legends, wallets, realPoints }: {
  dataPointsHolder: any,
  paths: any,
  reversedPaths: any,
  points: Array<any>,
  startX: number,
  startY: number,
  isReverse: boolean,
  dataPointDelay: number,
  dataPointMinSize: number,
  visibleLabels: number,
  version1: boolean,
  dataPointClickCallback: (node: any) => void,
  setPlayAudio: any,
  pulseWallets: boolean,
  legends: any,
  wallets: Array<{ degree: number, text: string, fullText: string }>
  realPoints: Array<LavaTransaction>,
  tooltipHolder: any
}) => {
  const pointData: any = []
  let rangeMin = 0
  let rangeMax = 2000

  const differentWallets: string[] = []

  realPoints.forEach(point => {
    const { from } = point
    if (differentWallets.indexOf(from) === -1) {
      differentWallets.push(from)
    }
  })

  for (let i = 0; i < realPoints.length; i++) {
    const { valueNumber, from } = realPoints[i]

    const endPointIndex = differentWallets.indexOf(from)

    // randomIndex
    let randIndex = randomIntFromInterval(0, visibleLabels - 1)

    if (endPointIndex < visibleLabels - 1) {
      randIndex = endPointIndex
    }

    // this will be the transaction size
    const radiusSize = (valueNumber && (valueNumber / 10)) || 0

    // animation start delay
    const delay = randomIntFromInterval(0, 50)

    // animation duration
    const duration = randomIntFromInterval(dataPointDelay, dataPointDelay + 500)

    // transaction endpoint
    const randomEndpoint = !isReverse ? paths[points[randIndex]] : reversedPaths[points[randIndex]]

    if (!randomEndpoint) {
      continue
    }

    // get the wallet data
    const wallet = wallets[randIndex]

    rangeMin = Math.min(rangeMin, radiusSize)
    rangeMax = Math.max(rangeMax, radiusSize)

    pointData.push({
      randIndex,
      radiusSize,
      delay,
      duration,
      randomEndpoint,
      startX,
      startY,
      wallet,
      data: realPoints[i]
    })
  }
  const newGroup = dataPointsHolder.append('g')

  const circles = newGroup
    .selectAll('circle')
    .data(pointData)
    .enter()
    .append('circle')
    .attr("cx", (d: any) => d.startX) //Starting x
    .attr("cy", (d: any) => d.startY) //Starting y
    .attr("r", (d: any) => d.radiusSize)
    .attr('fill', '#FFD439')
    .style('cursor', 'pointer')
    .on("mouseover",
      function (event: any, data: any) {
        const walletData = data.data
        // @ts-ignore
        select(this).attr('stroke', 'rgba(255,255,255,.5)').attr('stroke-width', '6px')
        tooltipHolder.style("opacity", .9);
        tooltipHolder.html(`value: ${walletData.valueNumber} | time: ${walletData.dateTime}`)
          .style("left", (event.pageX) + "px")
          .style("top", (event.pageY - 28) + "px");
      })
    .on("mouseout",
      function (d: any) {
        // @ts-ignore
        select(this).attr('stroke-width', '0px')
        tooltipHolder.style("opacity", 0);
      })
    .on("click",
      // @ts-ignore
      (_: any, d: any) => {
        dataPointClickCallback(d.data)
      })

  function pulseLegend(degree: string, color: string = '#FFD439', endColor: string = '#69a3b2', highlight: boolean = false) {
    legends
      .select(`#legend__${degree}`)
      .transition()
      .duration(200)
      .attr('fill', color)
      .style('font-size', `${highlight ? 16 : 12}px`)
      .on('start', function (d: any) {
        // @ts-ignore
        select(this)
          .style('filter', 'drop-shadow(0px 0px 6px rgba(255, 255, 255, 0.5))')
      })
      .on('end', function (d: any) {
        // @ts-ignore
        select(this)
          .transition()
          .duration(200)
          .attr('fill', endColor)
          .style('font-size', `12px`)
        // @ts-ignore  
        select(this)
          .style('filter', 'drop-shadow(0px 0px 0px rgba(255, 255, 255, 0.5))')
      })
  }

  circles.transition()
    .ease(easeCircleOut) //easeSin
    .delay((d: any) => d.delay)
    .duration((d: any) => d.duration)
    .tween("pathTween", function (d: any) {
      return pathTween(d.randomEndpoint)
    })
    .attr('r', (d: any) => version1 ? d.radiusSize : 1)
    .style("fill", "rgba(255, 0, 0,0.55)")
    .on('end', function (d: any) {
      if (d.wallet.degree !== 90 && d.wallet.degree !== '90' && pulseWallets) {
        pulseLegend(d.wallet.degree.toString(), "#94A7B8", '#69a3b2', false)
      }
      // @ts-ignore
      select(this)
        .transition()
        .duration(100)
        .attr('r', 0)
        .attr('fill', '#252d40')
        .on('end', function () {
          select(this).remove()
        })
    })

  pulseLegend('90')
  setPlayAudio()
}

const getD3Animation = (animationName: string) => {
  const animations: any = {
    'curveBumpX': curveBumpX
  }
  return animations[animationName]
}

const dummyWallets = [
  {
    degree: 0,
    text: 'VulcanDex',
    fullText: '',
  },
  {
    degree: 15,
    text: 'Wallet #1',
    fullText: '',
  },
  {
    degree: 30,
    text: 'Sinverse',
    fullText: '',
  },
  {
    degree: 45,
    text: 'Wallet #2',
    fullText: '',
  },
  {
    degree: 60,
    text: 'Fabies of Fyra',
    fullText: '',
  },
  {
    degree: 75,
    text: 'Wallet #3',
    fullText: '',
  },
  {
    degree: 90,
    text: '$Lava',
    fullText: '$Lava',
  },
  {
    degree: 105,
    text: 'Wallet #4',
    fullText: '',
  },
  {
    degree: 120,
    text: 'Berserk',
    fullText: '',
  },
  {
    degree: 135,
    text: 'Wallet #5',
    fullText: '',
  },
  {
    degree: 150,
    text: 'Bridge Network',
    fullText: '',
  },
  {
    degree: 165,
    text: 'Wallet #6',
    fullText: '',
  },
  {
    degree: 180,
    text: 'Husl',
    fullText: '',
  },
  {
    degree: 195,
    text: 'Wallet #7',
    fullText: '',
  },
  {
    degree: 210,
    text: 'Phalanx',
    fullText: '',
  },
  {
    degree: 225,
    text: 'Wallet #8',
    fullText: '',
  },
  {
    degree: 240,
    text: 'Vulcan Runner',
    fullText: '',
  },
  {
    degree: 255,
    text: 'Wallet #9',
    fullText: '',
  },
  {
    degree: 270,
    text: 'VulcanForged',
    fullText: '',
  },
  {
    degree: 285,
    text: 'Wallet #10',
    fullText: '',
  },
  {
    degree: 300,
    text: 'galacticFL',
    fullText: '',
  },
  {
    degree: 315,
    text: 'Wallet #11',
    fullText: '',
  },
  {
    degree: 330,
    text: 'NYC Fashion Brand',
    fullText: '',
  },
  {
    degree: 345,
    text: 'Wallet #12',
    fullText: '',
  },
]

const burningWallets = [
  {
    longName: '0x6ff57b3ae39b4aadcde9324106cfb99446d03683',
    shortName: '0x6ff57b3ae39...',
    url: 'https://api.polygonscan.com/api?module=account&action=tokentx&contractAddress=0xb4666B7402D287347DbBDC4EA5b30E80C376c0B3&startblock=0&endblock=99999999&page=1&offset=100&sort=desc&apikey=A6CDAUEPC48S2KJEE9BUCNX35GZIG16PCY&address=0x6ff57b3ae39b4aadcde9324106cfb99446d03683'
  },
  {
    longName: '0x9b06cb67bc5a79cb208653a3fe4dd317ecfbd98c',
    shortName: '0x9b06cb67bc5a...',
    url: 'https://api.polygonscan.com/api?module=account&action=tokentx&contractAddress=0xb4666B7402D287347DbBDC4EA5b30E80C376c0B3&startblock=0&endblock=99999999&page=1&offset=100&sort=desc&apikey=A6CDAUEPC48S2KJEE9BUCNX35GZIG16PCY&address=0x9b06cb67bc5a79cb208653a3fe4dd317ecfbd98c'
  },
  {
    longName: '0x5ccbe92aa3f3cc91e7e13aa0a288a8f83f64b2ed',
    shortName: '0x5ccbe92aa3f...',
    url: 'https://api.polygonscan.com/api?module=account&action=tokentx&contractAddress=0xb4666B7402D287347DbBDC4EA5b30E80C376c0B3&startblock=0&endblock=99999999&page=1&offset=100&sort=desc&apikey=A6CDAUEPC48S2KJEE9BUCNX35GZIG16PCY&address=0x5ccbe92aa3f3cc91e7e13aa0a288a8f83f64b2ed'
  }
]

function timeConverter(t: any) {
  var a = new Date(t * 1000);
  var today = new Date();
  var yesterday = new Date(Date.now() - 86400000);
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();

  if (a.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0))
    return 'today, ' + hour + ':' + min;
  else if (a.setHours(0, 0, 0, 0) == yesterday.setHours(0, 0, 0, 0))
    return 'yesterday, ' + hour + ':' + min;
  else if (year == today.getFullYear())
    return date + ' ' + month + ', ' + hour + ':' + min;
  else
    return date + ' ' + month + ' ' + year + ', ' + hour + ':' + min;
}


export const VulcanoChart = (props: {
  dataPointClickCallback: (node: any) => void,
  walletClickCallback: (node: any) => void,
  isOnlyTransactionChart: boolean,
  setOnlyTransactionChart: (bool: any) => void,
}) => {
  const { dataPointClickCallback, walletClickCallback, isOnlyTransactionChart, setOnlyTransactionChart } = props

  const [startX, setStartX] = useState(0)
  const [startY, setStartY] = useState(0)
  const [dataPointsHolder, setDataPointsHolder] = useState<any>(null)
  const [paths, setPaths] = useState(null)
  const [legends, setLegends] = useState<any>(null)
  const [reversedPaths, setReversedPaths] = useState(null)
  const [points, setPoints] = useState<Array<any>>([])
  const [isPlaying, setPlaying] = useState<boolean>(true)
  const [isReverse, setReverse] = useState<boolean>(false)
  const [isSettingsVisible, setSettingsVisible] = useState<boolean>(false)
  const [isTop24, setTop24] = useState(false)
  const [audioPlaying, audioToggle] = useState<boolean>(false)
  const [playAudio, setPlayAudio] = useAudio(notificationAudio)
  const [animation, setAnimation] = useState('curveBumpX')
  const [nodeGenerationDelay, setNodeGenerationDelay] = useState(2000)
  const [dataPointDelay, setDataPointDelay] = useState(6000)
  const [dataPointMinSize, setDataPointMinSize] = useState(10)
  const [version1, setVersion1] = useState(false)
  const [circleMask, setCircleMask] = useState(true)
  const [pulseWallets, setPulseWallets] = useState(true)
  const [isElysium, setElysium] = useState(false)
  const [resetable, setReset] = useState(false)
  const [wallets, setWallets] = useState<Array<{ degree: number, text: string, fullText: string }>>([])
  const [tooltipHolder, setTooltipHolder] = useState(select("body").append("div").attr("class", "tooltip").style("opacity", 0))
  const [isTransactionModal, setTransactionModal] = useState(false)
  const [transactions, setTransactions] = useState<Array<LavaTransaction>>([])
  const [pufferTransactions, setPufferTransactions] = useState<Array<LavaTransaction>>([])
  const [selectedTransaction, setTransaction] = useState<LavaTransaction>()
  const [isBgImage, setBgImage] = useState<boolean>(false)
  //const windowFocused = useWindowFocus()

  const chartContainer = useRef(null);

  const handleReset = () => {
    setWallets(dummyWallets.map(wallet => ({ ...wallet })))
    setReset(false)
  }

  const handledataPointClickCallback = (data: LavaTransaction) => {
    setTransactionModal(true)
    setTransaction(data)
  }

  const moveWalletToRoot = useCallback((text: string) => {
    const index = findIndex(wallets, { text: text })

    if (index !== -1) {
      setPlaying(false)
      const selectedText = wallets[index].text
      const changedWalletsPosition = [...wallets].map((props) => {
        if (props.degree === 90) {
          return { ...props, text: selectedText }
        }
        return { ...props, text: '' }
      })
      setReset(true)
      setWallets(changedWalletsPosition)
      tooltipHolder.style("opacity", 0);
      setTimeout(() => {
        setPlaying(true)
      }, 200)
    }
  }, [tooltipHolder, wallets])

  useEffect(
    () => {
      if (chartContainer.current) {
        select(chartContainer.current).selectAll("g").remove();

        // radius for the main circle
        const radius = 300
        const cx = 400
        const cy = 400

        const visibleLabels = isTop24 ? 24 : 12
        const divided = 360 / visibleLabels

        const line: any = link(getD3Animation(animation))
          .x(function (d: any) {
            return d.x;
          })
          .y(function (d: any) {
            return d.y;
          });

        const curve = (d: any) => {
          let x1 = d.source.x
          let y1 = d.source.y
          let x2 = d.target.x
          let y2 = d.target.y
          let degree = d.degree

          let ctrlX1 = x1
          let ctrlY1 = y2
          let ctrlX2 = x2
          let ctrlY2 = y2

          if (degree === 270) {
            return `
              M${x1},${y1}
              ${x2},${y2}
            `
          }

          // right side
          if (x1 < x2) {
            ctrlX1 += 60
            ctrlY1 -= 60
            if (degree === 300) {
              ctrlX1 -= 30
              ctrlY1 += 10
            }
            if (degree === 285) {
              ctrlX1 -= 45
              ctrlY1 += 40
            }
          }

          // left side
          if (x1 > x2) {
            ctrlX1 -= 60
            ctrlY1 -= 60
            if (degree === 240) {
              ctrlX1 += 30
              ctrlY1 -= 10
            }
            if (degree === 255) {
              ctrlX1 += 45
              ctrlY1 -= -40
            }
          }

          return `
            M${x1},${y1}
            Q${ctrlX1},${ctrlY1}
            ${x2},${y2}
           `
        }

        const width = '100%'
        const height = '100%'

        const chart = select(chartContainer.current)
          .attr("viewBox", `0 0 ${cx * 2} ${cy * 2}`)
          .attr("width", width)
          .attr("height", height);

        const circleHolder = chart.append('g').attr('id', 'mainCircleGroup')

        const pathsHolder = chart.append('g').attr('id', 'pathsGroup')
        setDataPointsHolder(chart.append('g').attr('id', 'dataPointsGroup'))
        const circleMaskHolder = chart.append('g').attr('id', 'mainCircleMaskGroup')
        const legendHolder = chart.append('g').attr('id', 'legendsGroup')

        const circle = circleHolder.append('circle')
          .attr('cx', cx)
          .attr('cy', cy)
          .attr('r', radius)
          .attr('stroke', '#1d2532')
          .attr('fill', isBgImage ? 'url(#lavaBgImage)' : '#131B26')

        if (circleMask) {
          circleMaskHolder.append('circle')
            .attr('cx', cx)
            .attr('cy', cy)
            .style('pointer-events', 'none')
            .attr('r', radius + 50)
            .attr('stroke', '#192434')
            .attr('stroke-width', '100px')
            .attr('fill', 'transparent');
        }

        const startX = calcX(90, radius, cx)
        const startY = calcY(90, radius, cy)

        let generatedPoints: Array<any> = []
        let generatedPaths: any = {}
        let reverseGeneratedPaths: any = {}

        const calc = (degree: number) => {
          let textAnchor = ''
          let dx = 0
          let dy = 0

          if (degree === 90 || degree === 270) {
            textAnchor = 'middle'
          }

          if (degree === 90) {
            dy = 45
          }

          if (degree === 270) {
            dy = -35
          }

          if (degree > 90 && degree < 270) {
            textAnchor = 'end'
            dx = -35
          }

          if ((degree > 270 && degree <= 360) || (degree >= 0 && degree < 90)) {
            textAnchor = 'start'
            dx = 35
          }
          return { dx, dy, textAnchor }
        }

        legendHolder
          .selectAll('.node-label')
          .data(wallets.filter(d => isTop24 ? d : d.degree % 30 === 0))
          .enter()
          .append('text')
          .on('click', function (d) {
            const selectedText = d.target.__data__.text
            moveWalletToRoot(selectedText)
            // walletClickCallback(d)
          })
          .on("mouseover",
            function (event: any, data: any) {
              tooltipHolder.style("opacity", .9);
              tooltipHolder.html(`wallet: ${data.fullText || data.text}`)
                .style("left", (event.pageX) + "px")
                .style("top", (event.pageY - 28) + "px");
            })
          .on("mouseout",
            function (d: any) {
              // @ts-ignore
              select(this).attr('stroke-width', '0px')
              tooltipHolder.style("opacity", 0);
            })
          .attr('id', (d) => `legend__${d.degree}`)
          .attr('x', (d) => getCircleX(degsToRads(90), radius) + cx)
          .attr('y', (d) => getCircleY(degsToRads(90), radius) + cy)
          .style('opacity', .15)
          .attr('fill', (d) => d.degree === 90 ? 'green' : '#69a3b2')
          .transition()
          .duration(500)
          .style('opacity', 1)
          .attr('x', (d) => getCircleX(degsToRads(d.degree), radius) + cx)
          .attr('y', (d) => getCircleY(degsToRads(d.degree), radius) + cy)
          .attr('dx', (d) => calc(d.degree).dx)
          .attr('dy', (d) => calc(d.degree).dy)
          .style("text-anchor", (d) => calc(d.degree).textAnchor)
          .style('cursor', 'pointer')
          .attr('fill', '#69a3b2')
          .text((d) => d.text)
          .attr('class', 'text')

        // generate/draw the labels and the path to the labels
        for (let i = 0; i < 360; i += divided) {
          generatedPoints.push(i.toString())

          const x2 = calcX(i, radius, cx)
          const y2 = calcY(i, radius, cy)

          generatedPaths[i.toString()] = pathsHolder.append("path")
            .attr("d", curve({
              source: { x: startX, y: startY },
              target: { x: startX, y: startY },
              degree: i,
            }))
            .transition()
            .duration(500)
            .attr("d", curve({
              source: { x: startX, y: startY },
              target: { x: x2, y: y2 },
              degree: i,
            }))
            .style('opacity', 0.75)
            .attr('class', `path_degree__${i}`)
            .style("stroke", "rgba(37,45,64,.75)")
            .style("fill", "none");

          reverseGeneratedPaths[i.toString()] = pathsHolder.append("path")
            .attr("d", curve({
              source: { x: x2, y: y2 },
              target: { x: startX, y: startY },
              degree: i,
            }))
            .attr('class', `reverse_path_degree__${i}`)
            .style("stroke", "transparent")
            .style("fill", "transparent");
        }

        setStartX(startX)
        setStartY(startY)
        setPaths(generatedPaths)
        setReversedPaths(reverseGeneratedPaths)
        setPoints(generatedPoints)
        setLegends(legendHolder)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animation, isTop24, walletClickCallback, wallets, circleMask, pulseWallets, isElysium, isBgImage])

  const addTransactionsToTheAnimation = useCallback((transactions: LavaTransaction[]) => {
    generateRandomData({
      dataPointsHolder,
      paths,
      reversedPaths,
      points,
      startX,
      startY,
      isReverse,
      dataPointDelay,
      dataPointMinSize,
      dataPointClickCallback: handledataPointClickCallback,
      pulseWallets,
      version1,
      visibleLabels: isTop24 ? 24 : 12,
      setPlayAudio: audioPlaying ? setPlayAudio : () => true,
      legends,
      wallets: isTop24 ? wallets.filter(wallet => wallet.degree % 15 === 0) : wallets.filter(wallet => wallet.degree % 30 === 0),
      realPoints: transactions,
      tooltipHolder
    })
  }, [audioPlaying, dataPointDelay, dataPointMinSize, dataPointsHolder, isReverse, isTop24, legends, paths, points, pulseWallets, reversedPaths, setPlayAudio, startX, startY, tooltipHolder, version1, wallets])


  const getDataNow = useCallback(async (init: boolean = false) => {

    const promise1 = axios.get(burningWallets[0].url);
    const promise2 = axios.get(burningWallets[1].url);
    const promise3 = axios.get(burningWallets[2].url);

    const responses = await Promise.all([promise1, promise2, promise3])
    const dataPointsArr = responses.map(response => {
      if (response.data.result === 'Max rate limit reached') {
        return []
      }
      return response.data.result
    })
    const dataPoints: LavaTransaction[] = [...dataPointsArr[0], ...dataPointsArr[1], ...dataPointsArr[2]]

    const fetchedDataPoints = dataPoints
      .sort((a, b) => Number(b.timeStamp) - Number(a.timeStamp))
      .map(transaction => {
        transaction.valueNumber = Number(transaction.value) / lavaValueDivide
        transaction.dateTime = timeConverter(transaction.timeStamp)
        transaction.timeStampNumber = Number(transaction.timeStamp)
        return transaction
      })

    const diffDataPoints = fetchedDataPoints
      .filter((transaction) => {
        const { blockHash } = transaction
        const found = find(transactions, { blockHash })
        if (!found) {
          return true
        }
        return false
      })

    if (init) {
      const last1hourTransactions = fetchedDataPoints.filter(transaction => {
        const nowTimeStampMinus1Hour = (new Date().getTime() / 1000) - 7200
        const timeStamp = transaction.timeStampNumber ? transaction.timeStampNumber : 1

        return timeStamp > nowTimeStampMinus1Hour
      })
      setTransactions([...transactions, ...diffDataPoints])
      setPufferTransactions(last1hourTransactions)
      console.log('last2hourTransactions', last1hourTransactions)
    }
    else {
      setTransactions([...transactions, ...diffDataPoints])
      setPufferTransactions(diffDataPoints)
    }
  }, [transactions])

  useEffectOnce(() => {
    if (!isElysium) {
      setWallets(dummyWallets.map(wallet => ({ ...wallet, text: wallet.fullText })))
      // INIT FETCH DATA
      getDataNow(true)
    }
  })

  useInterval(() => {
    getDataNow()
  }, !isElysium ? 5000 : null)

  useEffect(() => {
    if (!isElysium && chartContainer.current && pufferTransactions.length) {
      console.log('animatedTransactions', pufferTransactions)
      addTransactionsToTheAnimation(pufferTransactions)
      setPufferTransactions([])
    }
  }, [addTransactionsToTheAnimation, pufferTransactions, isElysium])

  useEffect(() => {
    if (isElysium) {
      setWallets(dummyWallets.map(wallet => ({ ...wallet })))
    }
    else {
      setWallets(dummyWallets.map(wallet => ({ ...wallet, text: wallet.fullText })))
    }
  }, [isElysium])

  useInterval(() => {
    if (chartContainer.current) {
      generateRandomDataOld({
        dataPointsHolder,
        paths,
        reversedPaths,
        points,
        startX,
        startY,
        isReverse,
        dataPointDelay,
        dataPointMinSize,
        dataPointClickCallback,
        pulseWallets,
        version1,
        visibleLabels: isTop24 ? 24 : 12,
        setPlayAudio: audioPlaying ? setPlayAudio : () => true,
        legends,
        wallets: isTop24 ? wallets.filter(wallet => wallet.degree % 15 === 0) : wallets.filter(wallet => wallet.degree % 30 === 0),
      })
    }
  }, isPlaying && isElysium ? nodeGenerationDelay : null);

  return (
    <div>
      {isTransactionModal && selectedTransaction && <Modal handleClose={() => setTransactionModal(false)}><TransactionInfo data={selectedTransaction} /></Modal>}
      <div id="vulcanoSettings">
        <div className="panel-title" style={{ paddingTop: 8 }}>$LAVA Transactions (<b>{isElysium ? 'Elysium' : 'Polygonscan'} LIVE!</b>)</div>
        <div style={{ position: 'absolute', top: 0, right: 10 }}>
          {resetable &&
            <Tooltip label='Reset wallets' hasArrow>
              <IconButton
                onClick={() => handleReset()}
                variant='outline'
                colorScheme='pink'
                aria-label='Reset'
                size="sm"
                style={{ marginRight: 10 }}
                icon={<IoRefreshOutline />} />
            </Tooltip>}

          <Tooltip label='Play/Pause' hasArrow>
            <IconButton
              onClick={() => isPlaying ? setPlaying(false) : setPlaying(true)}
              variant='outline'
              colorScheme='messenger'
              aria-label='Play/Pause'
              size="sm"
              style={{ marginRight: 10 }}
              icon={isPlaying ? <IoPause /> : <IoPlaySharp />} />
          </Tooltip>

          <Tooltip label='Toggle settings' hasArrow placement='left-start'>
            <IconButton
              onClick={() => isSettingsVisible ? setSettingsVisible(false) : setSettingsVisible(true)}
              variant='outline'
              colorScheme={isSettingsVisible ? 'pink' : 'messenger'}
              aria-label='Settings'
              size="sm"
              icon={<IoSettingsSharp />} />
          </Tooltip>
        </div>
      </div>
      {isSettingsVisible && <div style={{ position: 'absolute', top: 45, right: 10, background: '#131B26', padding: 24, borderRadius: 10, bottom: 10, overflow: 'auto' }}>
        <div>
          <div className='section-title'>Main settings</div>
          <div className='section-content'>
            <div className='section-content-row'>
              <span className='switch-btn'>
                <Switch size='md' isChecked={isElysium} onChange={(event) => {
                  setElysium(event.target.checked)
                }} />
                <span className="switch-btn-title">Elysium version</span>
              </span>
            </div>
            {!isElysium && <div className='section-content-row'>
              <Button style={{ marginRight: 10 }} size='xs' colorScheme='messenger' onClick={() => getDataNow(true)}>Fetch last 2hours transactions</Button>
            </div>}

          </div>
          <div className='section-title'>Visualization settings</div>
          <div className='section-content'>
            <div className='section-content-row'>
              <span className='switch-btn'>
                <Switch size='md' isChecked={!isOnlyTransactionChart} onChange={(event) => setOnlyTransactionChart(!event.target.checked)} />
                <span className="switch-btn-title">Show full dashboard</span>
              </span>
            </div>
            <div className='section-content-row'>
              <span className='switch-btn'>
                <Switch size='md' isChecked={version1} onChange={(event) => setVersion1(event.target.checked)} />
                <span className="switch-btn-title">Fixed transaction size</span>
              </span>
            </div>
            <div className='section-content-row'>
              <span className='switch-btn'>
                <Switch size='md' isChecked={circleMask} onChange={(event) => setCircleMask(event.target.checked)} />
                <span className="switch-btn-title">Circle mask</span>
              </span>
            </div>
            <div className='section-content-row'>
              <span className='switch-btn'>
                <Switch size='md' isChecked={pulseWallets} onChange={(event) => setPulseWallets(event.target.checked)} />
                <span className="switch-btn-title">Pulse wallets</span>
              </span>
            </div>
            <div className='section-content-row'>
              <span className='switch-btn'>
                <Switch size='md' isChecked={isBgImage} onChange={(event) => setBgImage(event.target.checked)} />
                <span className="switch-btn-title">Lava background</span>
              </span>
            </div>
          </div>
          <div className='section-title'>Notifications</div>
          <div className='section-content'>
            <div className='section-content-row'>
              <span className='switch-btn'>
                <Switch size='md' isChecked={audioPlaying} onChange={(event) => audioToggle(event.target.checked)} />
                <span className="switch-btn-title">Sound notification</span>
              </span>
            </div>
          </div>
          {isElysium && <>
            <div className='section-title'>Wallet settings</div>
            <div className='section-content'>
              <div className='section-content-row'>
                <span className='switch-btn'>
                  <Switch size='md' isChecked={isTop24} onChange={(event) => setTop24(event.target.checked)} />
                  <span className="switch-btn-title">Show Top 24</span>
                </span>
              </div>
              <div className='section-content-row'>
                <span className='switch-btn'>
                  <Switch size='md' isChecked={isReverse} onChange={(event) => setReverse(event.target.checked)} />
                  <span className="switch-btn-title">Reverse</span>
                </span>
              </div>
            </div>
            <div className='section-title'>Data settings</div>
            <div className='section-content'>
              <div style={{ width: 220, position: 'relative' }}>
                <div>
                  <span>Data points delay (3s - 10s)</span>
                  <Slider value={dataPointDelay} min={3000} max={10000} step={1000} onChange={(delay) => setDataPointDelay(delay)}>
                    <SliderTrack bg='red.100'>
                      <Box position='relative' right={10} />
                      <SliderFilledTrack bg='tomato' />
                    </SliderTrack>
                    <SliderThumb boxSize={6} />
                  </Slider>
                </div>
                <div>
                  <span>Data points min size</span>
                  <Slider value={dataPointMinSize} min={0} max={30} step={5} onChange={(delay) => setDataPointMinSize(delay)}>
                    <SliderTrack bg='red.100'>
                      <Box position='relative' right={10} />
                      <SliderFilledTrack bg='tomato' />
                    </SliderTrack>
                    <SliderThumb boxSize={6} />
                  </Slider>
                </div>
                <div>
                  <span>Erupt delay (1s - 6s)</span>
                  <Slider value={nodeGenerationDelay} min={1000} max={6000} step={500} onChange={(delay) => setNodeGenerationDelay(delay)}>
                    <SliderTrack bg='red.100'>
                      <Box position='relative' right={10} />
                      <SliderFilledTrack bg='tomato' />
                    </SliderTrack>
                    <SliderThumb boxSize={6} />
                  </Slider>
                </div>
              </div>
            </div>
          </>}

        </div>
      </div>}
      <svg ref={chartContainer}>
        <defs>
          <pattern id="lavaBgImage" height="100%" width="100%"
            patternContentUnits="objectBoundingBox">
            <image xlinkHref="lava-bg.png" preserveAspectRatio="none"
              width="1" height="1" />
          </pattern>
        </defs>
      </svg>
    </div>
  )
}