import React, { useState, useEffect, useMemo, useRef } from "react";

export const useAudio = (url: string) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  },
    [playing]
  );

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle] as const;
};

export const randomIntFromInterval = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min)

export const useEffectOnce = (effect: any) => {

  const destroyFunc: any = useRef();
  const calledOnce = useRef(false);
  const renderAfterCalled = useRef(false);

  if (calledOnce.current) {
    renderAfterCalled.current = true;
  }

  useEffect(() => {
    if (calledOnce.current) {
      return;
    }

    calledOnce.current = true;
    destroyFunc.current = effect();

    return () => {
      if (!renderAfterCalled.current) {
        return;
      }

      if (destroyFunc.current) {
        destroyFunc.current();
      }
    };
  }, []);
};

export interface LavaTransaction {
  blockHash: string
  blockNumber: string
  confirmations: string
  contractAddress: string
  cumulativeGasUsed: string
  from: string
  gas: string
  gasPrice: string
  gasUsed: string
  hash: string
  input: string
  nonce: string
  timeStamp: string
  to: string
  tokenDecimal: string
  tokenName: string
  tokenSymbol: string
  transactionIndex: string
  value: string

  valueNumber?: number
  dateTime?: string
  timeStampNumber?: number
  toWalletIndex?: number
}

const hasFocus = () => typeof document !== 'undefined' && document.hasFocus();

export const useWindowFocus = () => {
  const [focused, setFocused] = useState(hasFocus); // Focus for first render

  useEffect(() => {
    setFocused(hasFocus()); // Focus for additional renders

    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return focused;
};


/*const handleChangeBurningWallet = (wallet: any) => {
  setPlaying(false)
  setBurningWallet(wallet)
  getDataNow()
  setTimeout(() => {
    setPlaying(true)
  }, 200)
}*/


/*useInterval(() => {
    if (chartContainer.current) {
      generateRandomData({
        dataPointsHolder,
        paths,
        reversedPaths,
        points,
        startX,
        startY,
        isReverse,
        dataPointDelay,
        dataPointMinSize,
        dataPointClickCallback,
        pulseWallets,
        version1,
        visibleLabels: isTop24 ? 24 : 12,
        setPlayAudio: audioPlaying ? setPlayAudio : () => true,
        legends,
        wallets: isTop24 ? wallets.filter(wallet => wallet.degree % 15 === 0) : wallets.filter(wallet => wallet.degree % 30 === 0),
        realPoints,
        tooltipHolder
      })
    }
  }, isPlaying ? nodeGenerationDelay : null);*/


/* <div className='section-title'>Burning wallets</div>
        <div className='section-content'>
          <div className='section-content-row'>
            <div className='section-content-row'>
              {burningWallets.map((wallet: any) => <div key={wallet.url}><Button onClick={() => handleChangeBurningWallet(wallet)} style={{ marginBottom: 10 }} size='xs' colorScheme={selectedBurningWallet.url === wallet.url ? 'green' : 'messenger'}>{wallet.shortName}</Button></div>)}
            </div>
          </div>
        </div> */


/*const transformData = useCallback((data: any) => {
const fromWallet: any = {}
let walletData: any = []

// create a hashmap to get the biggest wallet, so we can cut the top12/24
data.forEach((row: any) => {
const { from, value }: any = row
const calcValue = Number(value) / lavaValueDivide

if (fromWallet[from]) {
fromWallet[from].value = fromWallet[from].value + calcValue
}
else {
fromWallet[from] = {
value: calcValue,
original: row
}
}
})

// hashmap to array
Object.keys(fromWallet).forEach(key => {
walletData.push({
walletAddress: key,
value: fromWallet[key].value,
original: fromWallet[key].original
})
});

let min: any = {}
let max: any = {}

// we have to sort the array by the value
walletData.sort((a: any, b: any) => b.value - a.value)

// min will be the latest, max the first
min = walletData[walletData.length - 1].value
max = walletData[0].value

//console.log(walletData, min, max)

let transformed: any = []
let degree = 0
let points: any = []

// top 12 wallets
for (let i = 0; i <= 12; i++) {
if (!walletData[i]) {
break
}

// at 90 degree there will be the root of the volcano the $LAVA
if (degree === 90) {
transformed.push({
degree,
text: '$LAVA'
})
}
else {
const walletAddress = walletData[i].walletAddress

transformed.push({
degree,
text: walletData[i].walletAddress.substr(0, 10) + '...',
fullText: walletData[i].walletAddress
})

// fill the points array with the transactions
data
.filter((row: any) => row.from === walletAddress)
.forEach((row: any) => {
  points.push({
    index: i,
    size: Number(row.value) / lavaValueDivide,
    data: walletData[i],
  })
})
}

// increase by 30 degree in every loop
degree += 30
}

if (!isEqual(points, realPoints)) {
setRealPoints(points)
setResponseWallets(transformed)
setWallets(transformed)
}
}, [realPoints])*/


/*   for (let i = 0; i < realPoints.length; i++) {
  const row: any = realPoints[i]
  const { size, index } = row
 
  const randIndex = index
 
  // this will be the transaction size
  const radiusSize = size
 
  // animation start delay
  const delay = randomIntFromInterval(0, 50)
 
  // animation duration
  const duration = randomIntFromInterval(dataPointDelay, dataPointDelay + 500)
 
  // transaction endpoint
  const randomEndpoint = !isReverse ? paths[points[randIndex]] : reversedPaths[points[randIndex]]
 
  if (!randomEndpoint) {
    continue
  }
 
  // get the wallet data
  const wallet = wallets[randIndex]
 
  rangeMin = Math.min(rangeMin, radiusSize)
  rangeMax = Math.max(rangeMax, radiusSize)
 
  pointData.push({
    randIndex,
    radiusSize,
    delay,
    duration,
    randomEndpoint,
    startX,
    startY,
    wallet,
    data: row
  })
} */

/*let rangeMin = 0
let rangeMax = 0

for (let i = 0; i < 30; i++) {
  // random sizes
  const randIndex = randomIntFromInterval(0, visibleLabels - 1)

  // this is the root point ($LAVA)
  if (wallets[randIndex].degree === 90) {
    continue
  }

  // this will be the transaction size
  const radiusSize = randomIntFromInterval(dataPointMinSize, dataPointMinSize + 20)
  // animation start delay
  const delay = randomIntFromInterval(0, 50)
  // animation duration
  const duration = randomIntFromInterval(dataPointDelay, dataPointDelay + 500)
  // transaction endpoint
  const randomEndpoint = !isReverse ? paths[points[randIndex]] : reversedPaths[points[randIndex]]
  // get the wallet data
  const wallet = wallets[randIndex]

  rangeMin = Math.min(rangeMin, radiusSize)
  rangeMax = Math.max(rangeMax, radiusSize)

  pointData.push({
    randIndex,
    radiusSize,
    delay,
    duration,
    randomEndpoint,
    startX,
    startY,
    wallet,
  })
}*/