
import { useState } from 'react'
import { CountUp } from 'use-count-up'

interface Props {
  num: string
  subtitle: string
  fakeCount?: boolean
  duration?: number
  prefix?: string
  animated?: boolean
}

export const KPIChart = ({ num, subtitle, fakeCount, duration, prefix, animated }: Props) => {
  const [change, setChange] = useState(false)
  const [prev, setPrev] = useState(parseInt(num))

  const handleChange =
    async (bool: boolean, value: any) => {
      if (animated === true && prev !== value) {
        setChange(bool)
        setPrev(value)
        setTimeout(() => {
          setChange(false)
        }, 200)
      }
    }

  const handelChangeFn = animated ? (value: any) => {
    handleChange(true, parseInt(value.replace(',', '')))
  } : () => null

  return (
    <div className="kpi-row">
      <div className={`kpi-number ${change === true ? 'kpi-number-active' : ''}`}>
        {prefix || ''}
        {fakeCount === true ?
          <CountUp
            updateInterval={animated === true ? 1 : 0}
            onUpdate={handelChangeFn}
            isCounting start={parseInt(num)}
            end={parseInt(num) * 2}
            duration={duration || 3600}
            thousandsSeparator={','} /> : num}
      </div>
      <div className="kpi-subtitle">{subtitle}</div>
    </div>
  )
}