import React, { useRef, useEffect } from 'react';
import { select, scaleBand, axisBottom, scaleLinear } from "d3";
import { useElementSize } from 'usehooks-ts'

import { randomIntFromInterval } from './utils';

const dummyData = [
  {
    "label": "JAN",
    "value": "12394"
  },
  {
    "label": "FEB",
    "value": "6148"
  },
  {
    "label": "MAR",
    "value": "4550"
  },
  {
    "label": "APR",
    "value": "5660"
  },
  {
    "label": "MAY",
    "value": "5400"
  },
  {
    "label": "JUN",
    "value": "6700"
  },
  {
    "label": "JUL",
    "value": "7100"
  },
  {
    "label": "AUG",
    "value": "8500"
  },
  {
    "label": "SEP",
    "value": "10000"
  },
  {
    "label": "OCT",
    "value": "12200"
  }
]

export const LollipopChart = () => {
  const chartContainer = useRef(null);
  const [containerRef, { width, height }] = useElementSize()

  useEffect(
    () => {
      if (chartContainer.current && width && height) {
        select(chartContainer.current).selectAll("*").remove();

        const margin = { top: 20, right: 30, bottom: 20, left: 0 }

        // append the svg object to the body of the page
        const svg = select(chartContainer.current)
          .attr("width", width)
          .attr("height", height)
          .append("g")
          .attr("transform", `translate(${margin.left},${margin.top})`);

        // X axis
        const x = scaleBand()
          .range([0, width])
          // @ts-ignore
          .domain(dummyData.map(function (d) { return d.label; }))
          .padding(1);

        svg.append("g")
          .attr("transform", `translate(0, ${height - 37})`)
          .call(axisBottom(x))
          .selectAll("text")
          .attr("transform", "translate(0,0)rotate(0)")
          .style("text-anchor", "middle")
          .attr('fill', '#758EA3');

        // Add Y axis
        const y = scaleLinear()
          .domain([0, 18000])
          .range([height, 0]);

        const points = svg.append("g").attr("transform", `translate(0, ${-37})`)

        // Lines
        points.selectAll("line")
          .data(dummyData)
          .enter()
          .append("line")
          // @ts-ignore
          .attr("x1", function (d) { return x(d.label); })
          // @ts-ignore
          .attr("x2", function (d) { return x(d.label); })
          // @ts-ignore
          .attr("y1", function (d) { return y(d.value); })
          .attr("y2", y(0))
          .attr("stroke", "#131B26")
          .attr("stroke-width", "8px")

        // Circles
        points.selectAll("circle")
          .data(dummyData)
          .join("circle")
          // @ts-ignore
          .attr("cx", function (d) { return x(d.label); })
          // @ts-ignore
          .attr("cy", function (d) { return y(d.value); })
          .attr("r", function (d) { return randomIntFromInterval(10, 25) })
          .style("fill", "#267B5D")
          .attr("stroke", "#131B26")
      }
    }, [height, width])

  return (
    <div ref={containerRef} style={{ flex: 1, width: '100%' }}>
      <svg ref={chartContainer} />
    </div>
  )
}