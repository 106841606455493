interface Props {
  children: JSX.Element
}

export const PanelContainer = ({ children }: Props) => {
  return (
    <div className="panel">
      {children}
    </div>
  )
}