import React, { useRef, useEffect } from 'react'
import { select, scaleBand, axisBottom, scaleLinear, line } from "d3"
import { useElementSize } from 'usehooks-ts'

const dummyData = [
  {
    "label": "01.00",
    "value": "12394"
  },
  {
    "label": "02.00",
    "value": "6148"
  },
  {
    "label": "03.00",
    "value": "4550"
  },
  {
    "label": "04.00",
    "value": "5660"
  },
  {
    "label": "05.00",
    "value": "5400"
  },
  {
    "label": "06.00",
    "value": "6700"
  },
  {
    "label": "07.00",
    "value": "7100"
  },
  {
    "label": "08.00",
    "value": "8500"
  },
  {
    "label": "09.00",
    "value": "10000"
  },
  {
    "label": "10.00",
    "value": "12200"
  },
  {
    "label": "11.00",
    "value": "10000"
  },
  {
    "label": "12.00",
    "value": "12200"
  },
  {
    "label": "13.00",
    "value": "15000"
  },
  {
    "label": "14.00",
    "value": "6500"
  },
  {
    "label": "15.00",
    "value": 0
  },
]

export const TimeLineChart = () => {
  const chartContainer = useRef(null)
  const [containerRef, { width, height }] = useElementSize()

  useEffect(
    () => {
      if (chartContainer.current && width && height) {
        select(chartContainer.current).selectAll("*").remove();

        const margin = { top: 20, right: 30, bottom: 20, left: 0 }
        const isSmallWidth = width < 500

        // append the svg object to the body of the page
        const svg = select(chartContainer.current)
          .attr("width", width)
          .attr("height", height + 30)
          .append("g")
          .attr("transform", `translate(${margin.left},${margin.top})`);

        // X axis
        const x = scaleBand()
          .range([0, width])
          // @ts-ignore
          .domain(dummyData.map(function (d) { return d.label; }))
          .padding(1);

        svg.append("g")
          .attr("transform", `translate(${isSmallWidth ? 10 : 0}, ${height - 37})`)
          .call(axisBottom(x))
          .selectAll("text")
          .attr("transform", `rotate(${isSmallWidth ? 45 : 0})`)
          .style("text-anchor", `${isSmallWidth ? 'start' : 'middle'}`)
          .attr('fill', '#758EA3');

        // Add Y axis
        const y = scaleLinear()
          .domain([0, 16000])
          .range([height, 0]);

        const bars = svg.append("g").attr("transform", `translate(0, ${-37})`)
        // Lines
        bars.selectAll("line")
          .data(dummyData)
          .enter()
          .append("line")
          // @ts-ignore
          .attr("x1", function (d) { return x(d.label); })
          // @ts-ignore
          .attr("x2", function (d) { return x(d.label); })
          // @ts-ignore
          .attr("y1", function (d) { return y(d.value); })
          .attr("y2", y(0))
          .attr("stroke", "#131B26")
          .attr("stroke-width", width / dummyData.length - 10)

        svg.append("path")
          .datum(dummyData)
          .attr("fill", "none")
          .attr("stroke", "steelblue")
          .attr("stroke-width", 3.5)
          // @ts-ignore
          .attr("d", line().defined(function (d) { return d.value != 0; }).x(function (d) { return x(d.label) }).y(function (d) { return y(d.value) }))
      }
    }, [height, width])

  return (
    <div ref={containerRef} style={{ flex: 1, width: '100%' }}>
      <svg ref={chartContainer} />
    </div>
  )
}