import { useEffect, useState } from 'react';

import './App.css';
import { MainContainer } from './containers/main.container';

function App() {

  return (
    <MainContainer />
  );
}

export default App;
