import { useEffect, useState } from 'react';

import { VulcanoChart } from '../charts/vulcano.chart';
import { PanelContainer } from '../containers/panel.container';
import { Flex, Spacer, Center, SimpleGrid, useColorMode } from '@chakra-ui/react'
import { Grid, GridItem, Box, VStack } from '@chakra-ui/react'
import { KPIChart } from '../charts/kpi.chart';
import { LollipopChart } from '../charts/lollipop.chart';
import { TableChart } from '../charts/table.chart';
import { TimeLineChart } from '../charts/timeline.chart';

export const MainContainer = () => {
  const [isOnlyTransactionChart, setOnlyTransactionChart] = useState<boolean>(
    localStorage.getItem('isOnlyTransactionChart') !== null ? !!localStorage.getItem('isOnlyTransactionChart') : true
  )

  return (
    <div id="container">
      <div className='dashboard-holder'>
        <div className={`dashboard-row main-dashboard-row ${isOnlyTransactionChart ? 'main-dashboard-row-full' : ''}`}>
          <div className={`dashboard-cell dashboard-cell-left ${isOnlyTransactionChart ? 'dashboard-cell-left-full' : ''}`}>
            <PanelContainer>
              <VulcanoChart
                isOnlyTransactionChart={isOnlyTransactionChart}
                setOnlyTransactionChart={(bool) => {
                  console.log(bool)
                  localStorage.setItem('isOnlyTransactionChart', bool);
                  setOnlyTransactionChart(bool)
                }}
                dataPointClickCallback={() => true}
                walletClickCallback={() => true}
              />
            </PanelContainer>
          </div>
          {!isOnlyTransactionChart && <div className='dashboard-cell dashboard-cell-right'>
            <div className='dashboard-right overflow-hidden'>
              <div className="panel-title panel-title-abs">$LAVA Burnt</div>
              <PanelContainer>
                <TimeLineChart />
              </PanelContainer>
            </div>
            <div className='dashboard-right overflow-hidden'>
              <div className="panel-title panel-title-abs">Tree planted</div>
              <PanelContainer>
                <LollipopChart />
              </PanelContainer>
            </div>
            <div className='dashboard-right'>
              <PanelContainer>
                <TableChart />
              </PanelContainer>
            </div>
          </div>}
        </div>
        {!isOnlyTransactionChart && <div className='dashboard-row-bottom'>
          <PanelContainer>
            <SimpleGrid columns={{ sm: 1, md: 2, lg: 5 }} spacingX='20px' alignItems={'stretch'} w='100%'>
              <Box>
                <KPIChart num={'23412'} fakeCount={true} subtitle={'Hourly average $LAVA burn'} duration={7200} />
              </Box>
              <Box>
                <KPIChart num={'321,910'} subtitle={'Highest hourly $LAVA burn'} />
              </Box>
              <Box>
                <KPIChart prefix='$' num={'65366'} subtitle={'Lava burnt'} fakeCount={true} duration={500000} animated={true} />
              </Box>
              <Box>
                <KPIChart num={'2,2'} subtitle={'Average burn'} />
              </Box>
              <Box>
                <KPIChart num={'31910'} fakeCount={true} subtitle={'Daily transactions'} duration={54000} />
              </Box>
            </SimpleGrid>
          </PanelContainer>
        </div>}

      </div>
    </div>
  );
}