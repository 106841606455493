import { Button } from "@chakra-ui/react"
import { LavaTransaction } from "../charts/utils";

function timeConverter(t: any) {
  var a = new Date(t * 1000);
  var today = new Date();
  var yesterday = new Date(Date.now() - 86400000);
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  if (a.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0))
    return 'today, ' + hour + ':' + min;
  else if (a.setHours(0, 0, 0, 0) == yesterday.setHours(0, 0, 0, 0))
    return 'yesterday, ' + hour + ':' + min;
  else if (year == today.getFullYear())
    return date + ' ' + month + ', ' + hour + ':' + min;
  else
    return date + ' ' + month + ' ' + year + ', ' + hour + ':' + min;
}

export const TransactionInfo = (props: { data: LavaTransaction }) => {
  const { data } = props
  const { valueNumber, from, to, blockNumber, transactionIndex, dateTime, hash } = data

  return (
    <div className="tile-row-container">
      <div className="tile-row">
        <div className="tile-container">
          <div className="tile-title">FROM</div>
          <div className="tile-num">{from}</div>
        </div>
      </div>
      <div className="tile-row">
        <div className="tile-container">
          <div className="tile-title">TRANSACTION VALUE</div>
          <div className="tile-num">{valueNumber}</div>
        </div>
        <div className="tile-container">
          <div className="tile-title">TIME</div>
          <div className="tile-num">{dateTime}</div>
        </div>
      </div>
      <div className="tile-row">
        <div className="tile-container">
          <div className="tile-title">BlockNumber</div>
          <div className="tile-num">{blockNumber}</div>
        </div>
        <div className="tile-container">
          <div className="tile-title">Transaction index</div>
          <div className="tile-num">{transactionIndex}</div>
        </div>
      </div>
      {/*  <div className="tile-row">
        <div className="tile-container">
          <div className="tile-title">RATE</div>
          <div className="tile-num">0.55%</div>
        </div>
        <div className="tile-container">
          <div className="tile-title">TIME</div>
          <div className="tile-num">3,000ms</div>
        </div>
      </div> */}
      <div className="modal-footer">
        <a target={'_blank'} href={`https://polygonscan.com/tx/${hash}`}>
          <Button size='lg' colorScheme='blue'>Show on Polygonscan</Button>
        </a>
      </div>
    </div>
  )
}